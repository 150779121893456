import React from "react";

export function DisplayBulkmailWarning({sendBulkmails}) {
    return (
        !sendBulkmails && (
            <p>
                Emails entered in the above field will be sent - including emails ending in bulkmail.ford.com
            </p>
        )
    );
}