import axios from "axios";
import {getRequestConfig} from "./ClientRequestHelper";

export default class BulkMailInfoAPIClient {
    constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
    }
    getBulkMailInfo(regions,priority) {
        const url = `${this.serviceUrl}/bulkmail/getByPriorityAndRegions?priority=${priority}&regions=${regions.join(',')}`;
        return axios.get(url, getRequestConfig())
            .then(response => {
                return Promise.resolve(response.data);
            }).catch(() => {
                return Promise.reject()
            })

    }
}
