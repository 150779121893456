import {statusAndPriorityDropdowns} from "./Common";
import Grid from "@material-ui/core/Grid";
import {FamTextField} from "../FamTextField";
import {replaceAngleBracketsWithWords} from "./CreateAdvisory/CreateAdvisory";
import React from "react";

export function displayStatusAndPriorityForEverythingButInformational(data, props, handleStatusChange) {
    return data.isInformational ?
        (<></>)
        :
        statusAndPriorityDropdowns(props, data, handleStatusChange, false);
}

export function productNameTextField(data) {
    return <Grid item xs={12}>
        <FamTextField
            id="product"
            label="Product / Application Name"
            helperText="Required"
            value={data.product}
            onChange={event => data.product = replaceAngleBracketsWithWords(event.target.value)}
            required={true}
        />
    </Grid>;
}


export function situationBackgroundTextField(data) {
    return <FamTextField
        id="situationbackground"
        label="Situation/Background"
        required={true}
        helperText="Required"
        placeholder="What do others need to know about the incident?"
        value={data.situationBackground.content}
        onChange={event =>
            data.situationBackground.content = replaceAngleBracketsWithWords(event.target.value)
        }
    />;
}

export function impactDetailsTextField(data) {
    return <FamTextField
        id="impactDetails"
        label="Impact details"
        placeholder="i.e. users are not able to"
        value={data.businessImpactDetails.impactDetails.content}
        onChange={event =>
            data.businessImpactDetails.impactDetails = {
                content: replaceAngleBracketsWithWords(event.target.value)
            }
        }
    />;
}