import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {observer} from "mobx-react";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const ProductSelector = observer(props => {
    const productCategories = [
        "Accessibility Index",
        "API Gateway",
        "App Identity Management",
        "Apps anywhere enablement applink/SDL/Livio",
        "Artificial Intelligence / Machine Learning Platform",
        "AV Command and Control",
        "AV connectivity channel for remote Guidance",
        "AV Data Transfer",
        "AV Digital Content Delivery",
        "AV Digital Partner Branding",
        "AV Find my Shared Vehicle",
        "AV Fleet Intelligence",
        "AV Fleet Servicing",
        "AV Fleet Telematics & VHA",
        "AV Interior Scan",
        "AV Mission Management",
        "AV MMOTA",
        "AV Onboarding and configuration management",
        "AV Provisioning & Supplier Feed",
        "AV Reset & Restore",
        "AV Vehicle Communication",
        "AV Vehicle Load & Unload",
        "AV Vehicle Mode Management",
        "AV Vehicle Staging",
        "Battery Tracking",
        "Bedrock",
        "Bill & Pay",
        "City Data",
        "Cloud Platform",
        "Commute Flow",
        "Connected Vehicle Authorization",
        "Connected Vehicle Data",
        "Connected Vehicle Notification Gateway (CVNG)",
        "Consumer Identity Management",
        "Curbspace Management",
        "Customer Campaign management",
        "Customer Communication",
        "Customer Consent Enablement",
        "Customer data",
        "Customer Preferences",
        "Customer Profile",
        "Customer Support",
        "CVC",
        "CV2X Services",
        "Data as a Service",
        "Deployment Service",
        "Developer Portal",
        "DevOps Automation",
        "Dispatch",
        "Driver Administration",
        "Electrification Platform",
        "Energy Services",
        "Engineering Tool Development",
        "Event Notification",
        "Financial Services",
        "Forced Upgrade",
        "Ford Next Customer Care",
        "FordPass",
        "FordPass & LincolnWay",
        "FordPro Intelligence",
        "Foundational Consumer Platforms",
        "Geofencing",
        "Global Account",
        "Global Owner",
        "Grid Integration",
        "Home Charging",
        "Intelligent Digital Assistance",
        "Inventory Management",
        "Journey Planning",
        "Knowledge Base",
        "Lead Management",
        "LincolnWay",
        "Loyalty Rewards Achievements",
        "Mapping",
        "Navigation",
        "Next Best Action / Intelligent Customer Interaction",
        "Offer Management",
        "Online Vehicle Service Booking",
        "Order Management",
        "Other",
        "Over the Air Updates",
        "Platform as Service",
        "PID Commands",
        "POI Search",
        "Population Health Tool",
        "Product Catalog",
        "Prognostics",
        "Public Charge Management",
        "Public Charging",
        "Real Time CV Data consumption/ processing/distribution",
        "Recall",
        "Remote Control Services",
        "Remote Diagnostics and Advanced Data Collection",
        "Roadside Assistance",
        "Routing",
        "Sales Analysis",
        "Smart Charging",
        "Social Media Monitoring and Response",
        "Solution & Health Monitoring",
        "SRE",
        "Stolen Vehicle Service",
        "TBox Commands",
        "Telemetry Feed",
        "Ticketing",
        "Time & Location Enabler",
        "TMC",
        "TMC Deployment Service",
        "TMC Geofencing",
        "TMC Manufacturer Admin",
        "TMC Native Device Commands",
        "TMC Ride Hailing Service",
        "TMC Telemetry Feed (GRPC)",
        "TMC Vehicle Communication",
        "TMC Vehicle Service",
        "Transit Data",
        "Transit Gap",
        "Transit Isochrone",
        "Transport Booking",
        "Transportation / Mobility Data",
        "Usage Management",
        "Value Added 3rd Party Services",
        "Vehicle Alerts, Report, and Health",
        "Vehicle Capability Check",
        "Vehicle Communication",
        "Vehicle Data Decoding",
        "Vehicle Data Distribution",
        "Vehicle Data Services",
        "Vehicle Service History",
        "Vehicle Share",
        "Vehicle Software Release",
        "Wallbox Enablement",
        "Warranty"
    ];

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel shrink>Product Category</InputLabel>
            <Select
                id="productCategorySelector"
                className={props.data.productCategory === "" ? "noSelection" : ""}
                displayEmpty
                input={<OutlinedInput notched labelWidth={165}/>}
                value={props.data.productCategory || ""}
                onChange={event => (props.data.productCategory = event.target.value)}
            >
                <MenuItem value="" disabled>
                    -- Select Product Category --
                </MenuItem>
                {productCategories.map((category, index) => {
                    const id = "menuItem" + index;
                    return (
                        <MenuItem id={id} key={category} value={category}>
                            {category}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
});
