import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import {FamTextField} from "../FamTextField";
import {replaceAngleBracketsWithWords} from "./CreateAdvisory/CreateAdvisory";
import {DisplayBulkmails} from "../DisplayBulkmails";

export function showAdvisoryPriorityDropdown(disabled, data, props) {
    return <>
        <InputLabel>Priority</InputLabel>
        <Select
            disabled={disabled}
            value={data.priority}
            data-testid={"prioritySelector"}
            onChange={event => props.handlePriorityChange(event)}
        >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
        </Select>
    </>;
}

export function finalAdvisoryWording(data) {
    if (data.status.includes("Executive")) return "Executive Final"
    else return "Final"
}

export function advisoryStatusDropdown(props, data, handleStatusChange, optionOneWording) {
    return <>
        <InputLabel> Advisory Status</InputLabel>
        <Select
            disabled={props.isFinalAdvisory}
            id="advisoryStatus"
            value={data.status}
            onChange={event => handleStatusChange(event)}
            data-testid={"statusSelector"}
        >
            <MenuItem value={optionOneWording(data)}>
                {optionOneWording(data)}
            </MenuItem>
            <MenuItem value={finalAdvisoryWording(data)}>
                {finalAdvisoryWording(data)}
            </MenuItem>


        </Select>
    </>;
}

export function statusAndPriorityDropdowns(props, data, handleStatusChange, disabled = false, optionOneWording = () => "Initial") {
    return <Grid item xs={12} className="statusPriorityContainer">
        <FormControl
            variant="filled"
            className={props.stylingClasses.selectors}
        >
            {advisoryStatusDropdown(props, data, handleStatusChange, optionOneWording)}
        </FormControl>
        <FormControl
            variant="filled"
            className={props.stylingClasses.selectors}
        >
            {showAdvisoryPriorityDropdown(disabled, data, props)}
        </FormControl>
    </Grid>;
}

export function informationalCheckbox(props, checked, disabled) {
    return <Grid item xs={12}>
        <FormControl variant="filled">
            <FormControlLabel
                control={
                    <Checkbox
                        id="informationalAdvisory"
                        value="Informational"
                        checked={checked}
                        disabled={disabled}
                        onChange={event =>
                            props.handleInformationalAdvisoryChange(event)
                        }
                        color="primary"
                    />
                }
                label="Informational Advisory"
            />
        </FormControl>
    </Grid>;
}

export function incidentOrAdvisoryLabel(status){
    if (status === "Informational") return "Advisory"
    else return "Incident"
}

export function removeOrAddPattern(state, removeButton, field, addButton) {
    if (state) {
        return <Grid item xs={12}>
            {removeButton}
            {field}
        </Grid>
    } else return addButton
}

export function showBusinessEndDate(props, data) {
    return props.showBiEndDate || data.businessImpactDetails.biEndDate;
}


export function addRootCauseButton(props) {
    return <Grid item xs={12}>
        <Link
            id="showRootCause"
            onClick={() => props.setShowRootCause(true)}
        >
            + ADD ROOT CAUSE
        </Link>
    </Grid>;
}

export function removeRootCauseButton(props) {
    return <Link onClick={() => props.handleRemoveRootCause()}>
        - REMOVE ROOT CAUSE
    </Link>;
}


export function rootCauseTextField(data) {
    return <FamTextField
        id="rootCause"
        label="Root cause if known"
        margin="normal"
        value={data.rootCause}
        onChange={event => data.rootCause = replaceAngleBracketsWithWords(event.target.value)}
    />;

}

export function showRootCause(props, data) {
    return props.showRootCause || data.rootCause;
}

export function removeOrAddRootCause(props, data) {
    let removeButton = removeRootCauseButton(props);
    let fieldToShow = rootCauseTextField(data);
    let addButton = addRootCauseButton(props);
    let removeOrAddRootCauseView = removeOrAddPattern(showRootCause(props, data), removeButton, fieldToShow, addButton);
    return removeOrAddRootCauseView;
}

export function showUsersImpactedField(data) {
    return <FamTextField
        id="usersImpacted"
        label="Users Impacted"
        value={data.businessImpactDetails.usersImpacted}
        onChange={event =>
            data.businessImpactDetails.usersImpacted =
                replaceAngleBracketsWithWords(event.target.value)
        }
    />;
}

export function showBulkmails(data, props) {
    return(
    <Grid container item>
        <Grid item xs={12}>
            <h2 className="darkSectionHeader">Send Email To</h2>
        </Grid>
        <Grid item xs={12}>
            <DisplayBulkmails
                data={data}
                bulkMail={props.bulkMail}
            />
        </Grid>
    </Grid>);
}