import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {informationalCheckbox, removeOrAddRootCause, rootCauseTextField, statusAndPriorityDropdowns} from "./Common";
import {businessImpactEndTimePicker, incidentEndDateTimePicker, removeOrAddBusinessImpactEndTime, removeOrAddEndTime} from "./DateTimePickers";
import {FamTextField} from "../FamTextField";
import {replaceAngleBracketsWithWords} from "./CreateAdvisory/CreateAdvisory";
import Button from "@material-ui/core/Button";
import {DurationDisplayHelper} from "../DurationDisplayHelper";

function htmlifySituationBackgroundHistory(listOfStuffToHtmlify, key) {
    return listOfStuffToHtmlify.map(function (update, index) {
        return (
            <Grid
                className="updateInformation"
                item
                xs={12}
                key={key + index}
            >
                <Grid container item xs={12} justify="space-between">
                    <Grid item>
                        {update.submittedTime.toLocaleString("en-US", {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZoneName: "short"
                        })}
                    </Grid>
                    <Grid item>{update.submittedBy}</Grid>
                </Grid>
                <TextField
                    className="updatedContent"
                    multiline
                    fullWidth
                    disabled
                    value={update.content}
                />
            </Grid>
        );
    });
}

function htmlifyInitialSituationBackground(data) {
    return <Grid item xs={12}>
        <Grid item container justify="space-between" xs={12}>
            <Grid item className="lightGray">
                {<>
                    {data.situationBackground.submittedTime ? data.situationBackground.submittedTime.toLocaleString(
                        "en-US",
                        {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZoneName: "short"
                        }
                    ) : null}
                </>}
            </Grid>
            <Grid item className="lightGray">
                {data.situationBackground.submittedBy}
            </Grid>
        </Grid>
        {<TextField
            className="updatedContent"
            multiline
            fullWidth
            disabled
            value={data.situationBackground.content}
        />}
    </Grid>;
}

export function htmlifyAllBusinessImpactHistory(data) {
    return <>
        <h3>Impact Details</h3>
        {htmlifySituationBackgroundHistory(data.businessImpactDetailsUpdates, "business-impact-update-")}
        {htmlifyInitialBusinessImpactDetails(data)}
    </>;
}

export function htmlifyAllSituationBackgroundHistory(data) {
    return <>
        <h3>Situation Background</h3>
        {htmlifySituationBackgroundHistory(data.situationBackgroundUpdates, "situation-background-update-")}
        {htmlifyInitialSituationBackground(data)}
    </>;
}

function htmlifyInitialBusinessImpactDetails(data) {
    return <>
        <Grid item container justify="space-between" xs={12}>
            <Grid item className="lightGray">
                {data.businessImpactDetails.impactDetails.submittedTime ? data.businessImpactDetails.impactDetails.submittedTime.toLocaleString(
                    "en-US",
                    {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short"
                    }
                ) : null}
            </Grid>
            <Grid item className="lightGray">
                {data.businessImpactDetails.impactDetails.submittedBy}
            </Grid>
        </Grid>
        <TextField
            className="updatedContent"
            multiline
            fullWidth
            disabled
            value={data.businessImpactDetails.impactDetails.content}
        />
    </>;
}

export function updateAdvisoryWording(data) {
    if (data.status.includes("Executive")) return "Executive Update"
    else return "Update"
}

export function showNothingIfFinalOrRemoveOrAddPattern(data, thingToShowIfNotFinal ) {
    if(statusIsFinal(data)) return <></>
    else return thingToShowIfNotFinal
}




export function statusIsFinal(data) {
    return data.status.includes("Final");
}




export function showRootCauseForNotFinalAdvisoriesAkaInLastAdvisoryAccordion(data, props) {
    let thingToShowIfNotFinal = removeOrAddRootCause(props, data);
    return showNothingIfFinalOrRemoveOrAddPattern(data,  thingToShowIfNotFinal)
}

export function showBusinessImpactEndTimeForNotFinal(data, props) {
    let thingToShowIfNotFinal = removeOrAddBusinessImpactEndTime(data, props)
    return showNothingIfFinalOrRemoveOrAddPattern(data,  thingToShowIfNotFinal)
}

export function endDateTimePickerWithAddAndRemoveButtons(data, props) {
    let thingToShowIfNotFinal = removeOrAddEndTime(data,props);
    return showNothingIfFinalOrRemoveOrAddPattern(data, thingToShowIfNotFinal)
}


export function updateSituationBackgroundField(data) {
    return <FamTextField
        required={true}
        id="updateSituationBackground"
        label={data.status + " Situation/Background"}
        helperText="Required"
        value={data.updateSituationBackground}
        onChange={event => data.updateSituationBackground = replaceAngleBracketsWithWords(event.target.value)}
    />;
}

export function applicationNameField(data) {
    return <TextField
        id="product"
        className="updatedContent"
        label="Product / Application Name"
        disabled
        multiline
        fullWidth
        InputLabelProps={{shrink: true}}
        value={data.product}
    />;
}

export function productCategoryField(data) {
    return <TextField
        helperText="Required"
        required={true}
        id="productCategory"
        className="updatedContent"
        label="Product Category"
        multiline
        fullWidth
        disabled
        InputLabelProps={{shrink: true}}
        value={data.productCategory}
    />;
}

export function businessImpactDetailsField(data) {
    return <FamTextField
        label={data.status + " Business Impact Details"}
        id="updateBusinessImpact"
        value={data.updateBusinessImpact}
        onChange={event => data.updateBusinessImpact = replaceAngleBracketsWithWords(event.target.value)}
    />;
}





export function submitButton(props, submitDisabled) {
    return <Button
        color="primary"
        variant="contained"
        id="submitEmailButton"
        disabled={submitDisabled}
        onClick={() => {
            document.getElementById("SendEmailLoader").hidden = false;
            props.handleSubmit();
        }}
    >
        Send Email
    </Button>;
}




export function displayInformationCheckboxOrStatusAndPriority(data, props, handleStatusChange) {
    if (data.isInformational) return informationalCheckbox(props, true, true)
    else return statusAndPriorityDropdowns(props, data, handleStatusChange, data.isFinalAdvisory || data.isExecutiveFinalAdvisory, updateAdvisoryWording);
}


export function getFinalSpecificFields(data) {
    return <>
        <Grid item xs={12}>
            {incidentEndDateTimePicker(data)}
        </Grid>
        <Grid item xs={12}>
            {businessImpactEndTimePicker(data)}
            <DurationDisplayHelper
                startDate={data.businessImpactDetails.biStartDate}
                endDate={data.businessImpactDetails.biEndDate}
            />
        </Grid>
        <Grid item xs={12}>
            {rootCauseTextField(data)}
        </Grid>
    </>;
}

export function displayIncidentEndForFinalAdvisory(data) {
    if (statusIsFinal(data)) return getFinalSpecificFields(data)
    else return <></>
}
