export function advisorySearch(searchCriteria, advisories) {
    const searchCriteriaInLowerCase = searchCriteria.toLowerCase();
    const searchCriteriaSplit = searchCriteriaInLowerCase.split(/\s|,|and/)
    return advisories.filter(
        advisory =>
            searchCriteriaSplit.filter(searchByKeyword =>
                advisory.advisoryForm.cdsid.toLowerCase().includes(searchByKeyword) ||
                advisory.advisoryForm.product.toLowerCase().includes(searchByKeyword) ||
                advisory.advisoryForm.status.toLowerCase().includes(searchByKeyword) ||
                advisory.id.toString().toLowerCase().includes(searchByKeyword) ||
                filterByDateIssued(advisory, searchByKeyword)
            ).length === searchCriteriaSplit.length
    );
}

function filterByDateIssued(advisory, searchCriteriaInLowerCase) {
    const dateInLocalString = advisory.advisoryForm.situationBackground.submittedTime.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric"
    });
    return dateInLocalString.includes(searchCriteriaInLowerCase) ||
        dateInLocalString.replace(/\//g, "-").includes(searchCriteriaInLowerCase);
}
