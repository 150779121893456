import React, {useEffect, useState} from "react";
import "../../../AdvisoryForm.css";
import {observer} from "mobx-react";
import {UpdateAdvisoryView} from "./UpdateAdvisoryView";
import {AdvisoryForm} from "../../../AdvisoryForm";
import {useParams} from "react-router-dom";


export const UpdateAdvisory = observer(
    ({cdsid, updateAdvisoryClient, bulkMailInfoAPIClient, executiveBulkMailAPIClient}) => {

        const [isDataLoaded, setDataLoaded] = useState(false);
        const [bulkMail, setBulkMail] = useState([]);
        const [formData, setFormData] = useState(new AdvisoryForm());


        let {updateId} = useParams();
        useEffect(() => {
            updateAdvisoryClient.get(updateId)
                .then(response => {
                    const data = response.advisoryForm;
                    data.regions.forEach(element => (formData.regions[element] = true));
                    formData.priority = data.priority;
                    formData.status = data.status === "Initial" || data.status === "Executive Initial" ? (data.status === "Executive Initial" ? "Executive Update" : "Update") : data.status;
                    formData.contactInfo = data.contactInfo;
                    formData.startDate = data.startDate;
                    formData.product = data.product;
                    formData.productCategory = data.productCategory;
                    formData.businessImpactDetails.biStartDate =
                        data.businessImpactDetails.biStartDate;
                    formData.endDate = data.endDate;
                    formData.businessImpactDetails.biEndDate =
                        data.businessImpactDetails.biEndDate;
                    formData.rootCause = data.rootCause;
                    formData.additionalEmails = data.additionalEmails;
                    formData.businessImpactDetails.usersImpacted =
                        data.businessImpactDetails.usersImpacted;
                    formData.businessImpactDetails.impactDetails =
                        data.businessImpactDetails.impactDetails;
                    formData.businessImpactDetailsUpdates =
                        data.businessImpactDetailsUpdates;
                    formData.situationBackground = data.situationBackground;
                    formData.situationBackgroundUpdates = data.situationBackgroundUpdates;
                    formData.sendBulkmails = data.sendBulkmails;
                    formData.cdsid = cdsid;

                    setFormData(formData);

                    if (formData.status.includes("Executive")) {
                        executiveBulkMailAPIClient.getBulkMailInfo(
                            formData.status,
                            formData.productCategory
                        ).then(response => {
                            setBulkMail(response);
                            setDataLoaded(true);
                        })
                            .catch(error => console.warn(error));
                    } else {
                        bulkMailInfoAPIClient.getBulkMailInfo(
                            formData.getRegionsFromForm(formData.regions),
                            formData.priority
                        ).then(response => {
                            setBulkMail(response);
                            setDataLoaded(true);
                        })
                            .catch(error => console.warn(error));
                    }

                })
                .catch(error => console.warn(error));
        }, [bulkMailInfoAPIClient, executiveBulkMailAPIClient, updateAdvisoryClient, updateId]);

        return (
            isDataLoaded ?
                <UpdateAdvisoryView
                    cdsid={cdsid}
                    formData={formData}
                    initialBulkMail={bulkMail}
                    bulkMailInfoAPIClient={bulkMailInfoAPIClient}
                    updateAdvisoryClient={updateAdvisoryClient}/>
                :
                <div>Loading...</div>
        );
    }
);
