import axios from "axios";
import {getRequestConfig} from "./ClientRequestHelper";

export default class ExecutiveBulkMailInfoAPIClient {
    constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
    }
    getBulkMailInfo(status,productCategory) {
        const url = `${this.serviceUrl}/bulkmail/getBulkmailByStatus?status=${status}&productCategory=${productCategory}`;
        return axios.get(url, getRequestConfig())
            .then(response => {
                return Promise.resolve(response.data);
            }).catch(() => {
                return Promise.reject()
            })

    }
}
