import constructConfig from "./config.js";
import {getNewDate} from "./DateCreator";
import jwt_decode from "jwt-decode";

export default class UserAuthenticationHandler {
    constructor() {
        this.config = constructConfig(window.location.hostname);
        this.redirectUrl = this.config.adfsUrl + window.location.href;
    }

    redirect() {
        window.location.replace(this.redirectUrl)
    }

    getToken() {
        const tokenFromURL = this.getTokenFromURL();
        if (tokenFromURL) {
            sessionStorage.setItem("token", tokenFromURL);
            return tokenFromURL
        }
        return sessionStorage.getItem("token");
    }

    getTokenFromURL() {
        const url = window.location.href;
        const match = url ? url.match(/access_token=([^&]+)/) : null;
        const clientId = url ? url.match(/client-request-id=([^&]+)/) : null;
        sessionStorage.setItem("client-request", clientId ? clientId[1] : null)
        return match ? match[1] : null;
    }

    removeTokenFromURL() {
        window.history.replaceState({}, document.title, window.location.pathname);
    }

    tokenIsExpired(token) {
        let currentTime = getNewDate().getTime();
        let expiredTime = jwt_decode(token).exp

        return currentTime / 1000 > expiredTime;
    }

    getTokenFromStorageAndValidate() {
        const token = sessionStorage.getItem("token");
        if(token !== null){
            if (this.tokenIsExpired(token) && this.config.shouldValidate) {
                this.redirect()
            } else {
                return token;
            }
        }
        else{
            this.redirect()
        }
    }
}
