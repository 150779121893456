import UserAuthenticationHandler from "./UserAuthenticationHandler";

export const getRequestConfig = () => {
    const handler = new UserAuthenticationHandler()
    const token = handler.getToken()
    return {
        'headers': {
            'Authorization': 'Bearer ' + token,
        }
    }
}