import React, {useState} from "react";
import './FeedbackForm.css'
import ReactStars from "react-rating-stars-component";
import Button from "@material-ui/core/Button";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {Header} from "../Header";


export class EmailFeedbackData {
    constructor(cdsid, name, feedbackText, clarityRating, concisenessRating, informationRating) {
        this.cdsid = cdsid
        this.nameAndRole = name
        this.feedbackMessage = feedbackText
        this.clarityRating = clarityRating
        this.concisenessRating = concisenessRating
        this.informationalRating = informationRating
    }

    cdsid = "";
    nameAndRole = "";
    feedbackMessage = "";
    clarityRating = "";
    concisenessRating = "";
    informationalRating = "";
    type = "Email";
}

export const EmailFeedbackForm = observer(
    ({submitFormClient, ...props}) => {
        const {trackEvent} = useMatomo();

        const [name, setName] = useState("");

        const [feedbackText, setfeedbackText] = useState("");
        const [clarityRating, setclarityRating] = useState("");
        const [concisenessRating, setconcisenessRating] = useState("");
        const [informationalRating, setinformationalRating] = useState("");
        let history = useHistory();


        const handleNameChange = event => {
            setName(event.target.value)
        }
        const handleFeedbackChange = event => {
            setfeedbackText(event.target.value)
        }

        const commonStuffInStars = onChangeFunction => {
            return {
                size: 100,
                count: 5,
                isHalf: true,
                value: 0,
                color: "#383838",
                activeColor: "#ffd700",
                onChange: newValue => {
                    onChangeFunction(newValue)
                }
            }
        }


        const handleSubmit = (event) => {
            event.preventDefault()
            const feedbackData = new EmailFeedbackData(props.cdsid, name, feedbackText, clarityRating.toString(), concisenessRating.toString(), informationalRating.toString())
            submitFormClient
                .submit(feedbackData)
                .then(() => {
                    history.push({
                        pathname: "/feedbackSuccessConfirmation"
                    });
                    trackEvent({
                        category: 'Email Feedback Submit Form',
                        action: 'Feedback Successful',
                        documentTitle: 'Ford Advisory Management',
                        href: 'advisory.sre.ford.com',
                    })
                })
                .catch(() => {
                    history.push({
                        pathname: "/feedbackFailureConfirmation"
                    });
                    trackEvent({
                        category: 'Email Feedback Submit Form',
                        action: 'Feedback failed',
                        documentTitle: 'Ford Advisory Management',
                        href: 'advisory.sre.ford.com',
                    })
                });
        }
        return (
            <>
                <Header title={"Feedback"} cdsid={props.cdsid} feedbackLink={false}/>
                <form onSubmit={handleSubmit}>
                    <div className={"feedback-Form"}>
                        <div className={"feedback-text"}>
                            <label>
                                1. What is your name & role at Ford?
                            </label>
                            <div className={"element"}>
                                <textarea type="text" id="nameTextArea" onChange={(e) => handleNameChange(e)}/>
                            </div>
                        </div>
                        <div className={"feedback-text"}>
                            <label>
                                2. How would you rate the formatting and organization of the email you received?
                            </label>
                            <div>
                                <div className={"starTextClarity"}>
                                    <ReactStars {...commonStuffInStars(setclarityRating)} />
                                </div>
                            </div>
                            <div>
                                <div className={"starTextConciseness"}>
                                    <ReactStars {...commonStuffInStars(setconcisenessRating)} />
                                </div>
                            </div>
                            <div className={"starTextInformativity"}>
                                <ReactStars {...commonStuffInStars(setinformationalRating)} />
                            </div>
                        </div>
                        <div className={"feedback-text"}>
                            <label>
                                3. Please share your thoughts and feedback for the FAM team below:
                            </label>
                            <div className={"feedbackInput"}>
                                <textarea id={"feedbackTextArea"} type="text"
                                          onChange={(e) => handleFeedbackChange(e)}/>
                            </div>
                        </div>
                        <div className={"submitButtonDiv"}>
                            <Button color="primary" variant="contained" className={"submitButton"}
                                    id="submitFeedbackButton"
                                    type={"submit"}>SUBMIT</Button>
                        </div>
                    </div>
                </form>
            </>
        );
    });

export default EmailFeedbackForm