import {ChipInputField} from "./ChipInputField";
import React from "react";

export function getContactInfoChipInputField(data, props, required = false) {
    let helperText
    if(required) helperText = "Required"; else helperText =  ""
    return <ChipInputField
        id="contactInfo"
        data-testid="contactInfoEmailsList"
        label="Contact/Escalate To"
        required={required}
        helperText = {helperText}
        updateData={chips => data.contactInfo = chips}
        invalidEmailSetter={props.setInvalidContactInfoEmail}
        preloadedData={data.contactInfo}
    />;
}

export function getAdditionalEmailsChipInputField(data, props) {
    return <ChipInputField
        id="additionalEmails"
        data-testid="additionalEmailsList"
        label="Email this advisory to"
        updateData={chips => data.additionalEmails = chips}
        invalidEmailSetter={props.setInvalidAdditionalEmail}
        preloadedData={data.additionalEmails}
    />;
}