import axios from "axios";
import {getRequestConfig} from "../../ClientRequestHelper";

export default class FeedbackFormClient {
    constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
    }
    submit(feedback) {
        const url = `${this.serviceUrl}/feedback`;
        return axios
            .post(url, feedback, getRequestConfig())
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(() => {
                return Promise.reject();
            });
    }
}