import React from "react";
import "./Confirmation.css";
import {Header} from "../Header";
import Grid from "@material-ui/core/Grid";
import {FAMCommunityLinks} from "./CommunityChannelInfo";

export const AdvisoryFailureConfirmation = (props) => {

    return (
        <>
            <Grid container spacing={3} className="pageContainer">
                <Header title={"Advisory Confirmation"} cdsid={props.cdsid}/>
                <br/>
                <div className="mainContent" id="failureMessage">
                    <h2><span className={"exclamation"}>&#9888;</span> Oops!</h2>
                    <p>Sorry, we encountered a problem saving/updating your advisory!</p>
                    <p>The FAM team has been notified and will look into the issue you've encountered.</p>
                    <p>In the meantime, please try to <a href={"/createAdvisory"}>create</a> your advisory again, or
                        reach out to us on our community channels:</p>
                    <FAMCommunityLinks/>
                    <br/>
                </div>
            </Grid>
        </>
    );
};