import React from "react";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ArrowDropDown} from "@material-ui/icons";

export const ViewAllAdvisoriesTableColumn = ({
                                                 sortBy,
                                                 setSortBy,
                                                 sortOrder,
                                                 setSortOrder,
                                                 columnName,
                                                 allFilteredAdvisories,
                                                 setAllFilteredAdvisories
                                             }) => {

    function sortById(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(isDesc, advisory1.id, advisory2.id);
            })
        );
    }

    function sortByDateIssued(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(
                    isDesc,
                    advisory1.advisoryForm.situationBackground.submittedTime,
                    advisory2.advisoryForm.situationBackground.submittedTime);
            })
        );
    }

    function sortByLastModified(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(
                    isDesc,
                    advisory1.advisoryForm.submittedTime,
                    advisory2.advisoryForm.submittedTime);
            })
        );
    }

    function sortByApplication(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(isDesc, advisory1.advisoryForm.product, advisory2.advisoryForm.product);
            })
        );
    }

    function sortByStatus(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(isDesc, advisory1.advisoryForm.status, advisory2.advisoryForm.status);
            })
        );
    }

    function sortByModifiedBy(isDesc) {
        setAllFilteredAdvisories(
            allFilteredAdvisories.slice().sort(function (advisory1, advisory2) {
                return comparator(isDesc, advisory1.advisoryForm.cdsid, advisory2.advisoryForm.cdsid);
            })
        );
    }

    function comparator(isDesc, value1, value2) {
        return isDesc
            ? ((value1 > value2) ? 1 : -1)
            : ((value1 < value2) ? 1 : -1);
    }

    function handleSortingByColumn() {
        const isDesc = sortBy === columnName && sortOrder === "desc";
        switch (columnName) {
            case "ID":
                sortById(isDesc);
                break;
            case "Date Issued":
                sortByDateIssued(isDesc);
                break;
            case "Application":
                sortByApplication(isDesc);
                break;
            case "Status":
                sortByStatus(isDesc);
                break;
            case "Last Modified":
                sortByLastModified(isDesc);
                break;
            case "Modified By":
                sortByModifiedBy(isDesc);
                break;
            default:
                break;
        }
        setSortOrder(isDesc ? "asc" : "desc");
        setSortBy(columnName);
    }

    return (
        <TableSortLabel
            active={sortBy === columnName}
            direction={sortBy === columnName ? sortOrder : "desc"}
            onClick={() => {
                handleSortingByColumn();
            }}
            IconComponent={ArrowDropDown}
        >
            {columnName}
        </TableSortLabel>
    );
};
