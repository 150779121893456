import axios from "axios";
import {getRequestConfig} from "../ClientRequestHelper";

export default class GetHealthAPIClient {

    constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
    }

    getHealth() {
        const url = `${this.serviceUrl}/healthcheck/email`;
        return axios.get(url, getRequestConfig())
            .then(response => {
                return Promise.resolve(response.data.status);
            }).catch((error) => {
                return Promise.reject(error)
            });
    }
}