import React from "react";
import {useLocation} from "react-router-dom";
import {Header} from "../Header";
import Grid from "@material-ui/core/Grid";
import "./Confirmation.css";
import {FAMCommunityLinks} from "./CommunityChannelInfo";

export const AdvisorySuccessConfirmation = (props) => {
    const location = useLocation();
    if (location.state !== undefined) {
    return (
        <>
            <Grid container spacing={3} className="pageContainer">
                <Header title={"Advisory Confirmation"} cdsid={props.cdsid}/>
                <br/>
                <div className="mainContent" id="successMessage">
                    <h2><span className={"checkMark"}>&#9745;</span>Advisory has been sent!</h2>
                    <h4>An Email advisory has been sent to:</h4>
                    <ul>
                        {location.state.emails.map(email => {
                            return <li key={email}>{email}</li>;
                        })}
                    </ul>
                    <p>How was your experience? Help us improve.</p>
                    <FAMCommunityLinks/>
                    <br/>
                </div>
            </Grid>
        </>
    )
    }
    else {
        return (
            <div className="mainContent">
                <h3>404 : Page Not Found</h3>
            </div>
        );
    }
};
