import {configure, makeAutoObservable} from "mobx";
import {getNewDate} from "./DateCreator";

configure({
  enforceActions: "never",
})

export class AdvisoryForm {
  initialDate = getNewDate();
  cdsid = "";
  status = "Initial";
  priority = "Medium";
  startDate = this.initialDate;
  endDate = null;
  contactInfo = [];
  product = "";
  productCategory = "";
  situationBackground = {
    submittedBy: "",
    submittedTime: null,
    content: ""
  };
  updateSituationBackground = "";
  situationBackgroundUpdates = [];
  businessImpactDetails = {
    biStartDate: this.initialDate,
    biEndDate: null,
    usersImpacted: "",
    impactDetails: {
      submittedBy: "",
      submittedTime: null,
      content: ""
    }
  };
  updateBusinessImpact = "";
  businessImpactDetailsUpdates = [];
  rootCause = "";
  additionalEmails = [];
  regions = {
    greaterChina: false,
    internationalMarketGroup: false,
    northAmerica: false,
    europeanUnion: false,
    southAmerica: false
  };
  formLoadTime = this.initialDate;
  sendBulkmails = true;

  constructor() {
    makeAutoObservable(this)
  }
  getRegionsFromForm = _regions => {
    let regionNamesToSubmit = [];
    let allRegionNames = Object.keys(_regions);
    for (let index in allRegionNames) {
      let regionName = allRegionNames[index];
      let isRegionChecked = _regions[regionName];
      if (isRegionChecked) {
        regionNamesToSubmit.push(regionName);
      }
    }
    return regionNamesToSubmit;
  };

  get toJson() {
    const submittedTime = getNewDate();

    return {
      status: this.status,
      priority: this.priority,
      startDate: this.startDate.toISOString().slice(0,-1),
      endDate: this.endDate
        ? this.endDate.toISOString().slice(0,-1)
        : null,
      contactInfo: this.contactInfo,
      product: this.product,
      productCategory: this.productCategory,
      situationBackground: {
        submittedBy: this.cdsid,
        submittedTime: this.situationBackground.submittedTime
            ? this.situationBackground.submittedTime.toISOString().slice(0,-1)
            : submittedTime.toISOString().slice(0,-1),
        content: this.situationBackground.content
            ? this.situationBackground.content
            : ""
      },
      situationBackgroundUpdates: this.updateSituationBackground
          ? [
            {
              submittedBy: this.cdsid,
              submittedTime: submittedTime.toISOString().slice(0,-1),
              content: this.updateSituationBackground
            }
          ].concat(this.situationBackgroundUpdates)
          : this.situationBackgroundUpdates,
      businessImpactDetails: {
        biStartDate: this.businessImpactDetails.biStartDate.toISOString().slice(0,-1),
        biEndDate: this.businessImpactDetails.biEndDate
          ? this.businessImpactDetails.biEndDate.toISOString().slice(0,-1)
          : null,
        usersImpacted: this.businessImpactDetails.usersImpacted,
        impactDetails: {
          submittedBy: this.cdsid,
          submittedTime: this.businessImpactDetails.impactDetails.submittedTime
              ? this.businessImpactDetails.impactDetails.submittedTime.toISOString().slice(0,-1)
              : submittedTime.toISOString().slice(0,-1),
          content: this.businessImpactDetails.impactDetails.content
              ? this.businessImpactDetails.impactDetails.content
              : ""
        }
      },
      businessImpactDetailsUpdates: this.updateBusinessImpact
          ? [
            {
              submittedBy: this.cdsid,
              submittedTime: submittedTime.toISOString().slice(0,-1),
              content: this.updateBusinessImpact
            }
          ].concat(this.businessImpactDetailsUpdates)
          : this.businessImpactDetailsUpdates,
      rootCause: this.rootCause,
      additionalEmails: this.additionalEmails,
      regions: this.getRegionsFromForm(this.regions),
      cdsid: this.cdsid,
      submittedTime: submittedTime.toISOString().slice(0,-1),
      formLoadTime: this.formLoadTime.toISOString().slice(0,-1),
      sendBulkmails: this.sendBulkmails
    };
  }

  get isInformational() {
    return this.status === "Informational"
  }
}


