import React from "react";
export function DisplayErrorMessage({content}) {

    return (
        <>
            {content !== "" && content !== undefined && (
                <p className="emailErrorMessage">
                    {content}
                </p>
            )}
        </>

    );
}
