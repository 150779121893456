import React, {useEffect, useRef, useState} from 'react';

const useStopwatch = (initialTimeRunning = true) => {
    const [timeIsRunning, setTimeIsRunning] = useState(initialTimeRunning)
    const timeInMillisRef = useRef(0)
    let timer = useRef()

    useEffect(() => {
        if (timeIsRunning) {
            timer.current = setInterval(() => {
                timeInMillisRef.current+= 10
            }, 10)
        } else {
            clearInterval(timer.current)
        }
    }, [timeIsRunning])

    const start = () => setTimeIsRunning(true)
    const pause = () => setTimeIsRunning(false)

    return {timeInMillisRef, timeIsRunning, start, pause};
};

export default useStopwatch;
