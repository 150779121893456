const flatten = require('flat');

export function jsonToCsvDataFormatter(advisories) {
    let tempData = [];
    advisories.forEach(it => {
        const record = {
            id: it.id,
            ...flatten(it.advisoryForm)
        };
        tempData.push(record);
    });
    return tempData;
}