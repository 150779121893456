import React from "react";
import Grid from "@material-ui/core/Grid";
import {Header} from "../Header";
import './Confirmation.css'
import {FAMCommunityLinks} from "./CommunityChannelInfo";

export const FeedbackSuccessConfirmation = (props) => {

    return (
        <>
            <Grid className="pageContainer">
                <Header cdsid={props.cdsid} title={"Feedback Confirmation"} feedbackLink={false}/>
                <br/>
                <div className={"mainContent"}>
                    <h2><span className={"checkMark"}>&#9745;</span> Thanks!</h2>
                    <p>The FAM team will follow up with you within 2 business days.</p>
                    <FAMCommunityLinks/>
                    <br/>
                </div>
            </Grid>

        </>
    );
};