import React from "react";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import createConfig from "../../config";


export const slackChannelLabel = "Production-Eng Team"
export const slackChannelURL = "https://ford.enterprise.slack.com/archives/C02P3L0SKNU"

export const webexSpaceLabel = "SRE Community"
export const webexSpaceURL = "https://www.webexteams.ford.com/space?r=g8th"

export const FAMCommunityLinks = ()=>{
    const config = createConfig();
    let {trackEvent} = useMatomo();
    return(
        <>
            <p><strong>Slack: <a href={slackChannelURL} target={"_blank"}
                                 onClick={() => {
                                     trackEvent({
                                         category: 'Community',
                                         action: 'Slack Community link clicked',
                                         documentTitle: 'Ford Advisory Management',
                                         href: config.baseUrl,
                                     })
                                 }}>{slackChannelLabel}</a></strong>
            </p>
            <p><strong>WebEx:</strong> Search for <strong><a href={webexSpaceURL}
                                                             onClick={() => {
                                                                 trackEvent({
                                                                     category: 'Community',
                                                                     action: 'Webex Community link clicked',
                                                                     documentTitle: 'Ford Advisory Management',
                                                                     href: config.baseUrl,
                                                                 })
                                                             }}>{webexSpaceLabel}</a></strong>
            </p>
            <p>Go back to the <strong><a href={config.baseUrl}>Ford Advisory Management
                Homepage</a></strong></p>
        </>
    )
}