import React from "react";

import {RegionSelector} from "../../RegionSelector";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import {observer} from "mobx-react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {MandatoryFieldsMessage} from "../../DisplayInvalidEmailMessage";
import {DisplayBulkmailWarning} from "../../DisplayBulkmailWarning";
import {Header} from "../../Header";
import {getAdditionalEmailsChipInputField, getContactInfoChipInputField} from "../../FormComponents";
import {showBulkmails, showUsersImpactedField} from "../Common";
import {
    applicationNameField,
    businessImpactDetailsField,
    displayIncidentEndForFinalAdvisory,
    displayInformationCheckboxOrStatusAndPriority,
    endDateTimePickerWithAddAndRemoveButtons,
    htmlifyAllBusinessImpactHistory,
    htmlifyAllSituationBackgroundHistory,
    productCategoryField,
    showBusinessImpactEndTimeForNotFinal,
    showRootCauseForNotFinalAdvisoriesAkaInLastAdvisoryAccordion,
    submitButton,
    updateSituationBackgroundField
} from "../UpdateSpecificComponents";
import {incidentStartDateTimePicker, showBusinessImpactStartTime} from "../DateTimePickers";


export const UpdateAdvisoryForm = observer(
    ({data, handleStatusChange, handleRegionChange, submitDisabled, ...props}) => {

        return (
            <>
                <Header title={data.status + " Advisory"} updateId={"#" + props.updateId} cdsid={props.cdsid}/>
                <Grid container spacing={3} className="pageContainer">
                    {displayInformationCheckboxOrStatusAndPriority(data, props, handleStatusChange)}
                    <>
                        <Grid item xs={12}>
                            <h2 className="sectionHeaders updateHeader">Update Details</h2>
                            <p className="bodyText">
                                The updated text in this section will appear at the top of the email
                                advisory.
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            {updateSituationBackgroundField(data)}
                        </Grid>
                        <Grid item xs={12}>
                            {businessImpactDetailsField(data)}
                        </Grid>
                    </>
                    {displayIncidentEndForFinalAdvisory(data)}
                    <Accordion elevation={0} defaultExpanded={true}>
                        <AccordionSummary
                            id="lastAdvisorySummary"
                            expandIcon={<ExpandMoreIcon className="expandIcon"/>}
                        >
                            <Grid item xs={12}>
                                <Typography className="darkSummarySection">
                                    Last Advisory Details
                                </Typography>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <RegionSelector
                                    handleRegionChange={handleRegionChange}
                                    data={data}
                                />
                                <Grid item xs={12}>
                                    {applicationNameField(data)}
                                </Grid>
                                <Grid item xs={12}>
                                    {productCategoryField(data)}
                                </Grid>
                                <Grid item xs={12}>
                                    {incidentStartDateTimePicker(data)}
                                </Grid>
                                {endDateTimePickerWithAddAndRemoveButtons(data, props)}
                                <Grid item xs={12}>
                                    {getContactInfoChipInputField(data, props)}
                                </Grid>
                                <Grid item xs={12}>
                                    {htmlifyAllSituationBackgroundHistory(data)}
                                </Grid>
                                {showRootCauseForNotFinalAdvisoriesAkaInLastAdvisoryAccordion(data, props)}
                                <Grid item xs={12}>
                                    <h2 className="sectionHeaders">Business Impact Details</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    {showBusinessImpactStartTime(data)}
                                </Grid>
                                {showBusinessImpactEndTimeForNotFinal(data, props)}
                                <Grid item xs={12}>
                                    {showUsersImpactedField(data)}
                                    {htmlifyAllBusinessImpactHistory(data)}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {showBulkmails(data, props)}
                    <Grid item xs={12}>
                        {getAdditionalEmailsChipInputField(data, props)}
                        <DisplayBulkmailWarning sendBulkmails={data.sendBulkmails}/>
                    </Grid>
                    <Grid item xs={12} className="GenerateEmailButton">
                        {MandatoryFieldsMessage(submitDisabled())}
                        {submitButton(props, props.invalidEmails !== "" || submitDisabled())}
                        <br/>
                        <br/>
                        <div className="loader" id="SendEmailLoader" hidden={true}/>
                    </Grid>
                </Grid>
            </>
        );
    }
);
