import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import ChipInput from "material-ui-chip-input";
import {DisplayInvalidEmailMessage} from "./DisplayInvalidEmailMessage";

const validEmailPattern = /^[A-Za-z0-9+_.-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ChipInputField = observer(({invalidEmailSetter, updateData, preloadedData, ...props}) => {
    const [invalidChip, setInvalidChip] = useState("");
    let [chips, setChips] = useState([]);

    useEffect(() => {
        if (preloadedData) {
            chips = preloadedData
            setChips(preloadedData)
        }
    }, [preloadedData])

    const handleRequestAdd = (...chipsToAdd) => {
        let tempChips = [...chips, ...chipsToAdd]
        if (updateData)
            updateData(tempChips)
        setChips(tempChips)
    }

    const handleRequestDelete = (chipsToDelete) => {
        let tempChips = chips.filter((chip) => chip !== chipsToDelete)
        if (updateData)
            updateData(tempChips)
        setChips(tempChips)
    }

    const handleEmailValidation = (chip) => {
        if (validEmailPattern.test(chip)) {
            invalidEmailSetter("");
            setInvalidChip("");
            return true;
        }
        invalidEmailSetter(chip);
        setInvalidChip(chip);
        return validEmailPattern.test(chip);
    }

    return (
        <>
            <ChipInput
                fullWidth
                onPaste={(event) => {
                    const clipboardText = event.clipboardData.getData('Text')
                    event.preventDefault()

                    let listOfChips = clipboardText.split(/[\s,]+/).filter((text) => text.length > 0);

                    const invalidChips = listOfChips.filter((chip) => validEmailPattern.test(chip) === false)
                    handleEmailValidation(invalidChips.join(" "))
                    const validatedChipsToAdd = listOfChips.filter((chip) => validEmailPattern.test(chip) === true)
                    handleRequestAdd(...validatedChipsToAdd)
                }}
                onAdd={(chip) => handleRequestAdd(chip)}
                onDelete={(chip) => handleRequestDelete(chip)}
                variant="outlined"
                newChipKeys={["Enter", ",", ";", " "]}
                value={chips}

                {...props}
                InputLabelProps={{shrink: true}}
                onBeforeAdd={chip => handleEmailValidation(chip)}
            />
            <DisplayInvalidEmailMessage invalidEmails={invalidChip}/>
        </>
    );
});
