import {Route, Switch, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import CreateAdvisoryAPIClient from "./components/AdvisoryForms/CreateAdvisory/CreateAdvisoryAPIClient";
import UpdateAdvisoryAPIClient from "./components/AdvisoryForms/UpdateAdvisory/UpdateAdvisoryAPIClient";
import {CreateAdvisory} from "./components/AdvisoryForms/CreateAdvisory/CreateAdvisory";
import createConfig from "./config";
import {AdvisoryForm} from "./AdvisoryForm.js";
import {UpdateAdvisory} from "./components/AdvisoryForms/UpdateAdvisory/UpdateAdvisory";
import BulkMailInfoAPIClient from "./BulkMailInfoAPIClient";
import {ViewAllAdvisories} from "./components/ViewAllAdvisories/ViewAllAdvisories";
import ViewAllAdvisoriesAPIClient from "./components/ViewAllAdvisories/ViewAllAdvisoriesAPIClient";
import {AdvisorySuccessConfirmation} from "./components/Confirmation/AdvisorySuccessConfirmation";
import {AdvisoryFailureConfirmation} from "./components/Confirmation/AdvisoryFailureConfirmation";
import {SiteFeedbackForm} from './components/FeedbackForm/SiteFeedbackForm'
import GetHealthAPIClient from "./components/GetHealthAPIClient";
import FeedbackFormClient from "./components/FeedbackForm/FeedbackFormClient";
import EmailFeedbackForm from "./components/FeedbackForm/EmailFeedbackForm";
import {FeedbackFailureConfirmation} from "./components/Confirmation/FeedbackFailureConfirmation";
import {FeedbackSuccessConfirmation} from "./components/Confirmation/FeedbackSuccessConfirmation";
import {createMuiTheme} from "@material-ui/core/styles";
import {MuiThemeProvider} from "@material-ui/core";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import ExecutiveBulkMailInfoAPIClient from "./ExecutiveBulkMailInfoAPIClient";
import Footer from "./components/Footer";


export default function App(props) {
    const [cdsid, setCdsid] = useState("notFound")
    const [token, setToken] = useState(props.authenticationHandler.getToken())
    const config = createConfig();
    const [createFormData, setCreateFormData] = useState(new AdvisoryForm())

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiFormLabel: {
                asterisk: {
                    color: '#db3131'
                }
            },
            MuiFormHelperText: {
                root: {
                    color: '#db3131',
                    textAlign: 'right'
                }
            }
        }
    })


    let location = useLocation();
    const {trackPageView, pushInstruction} = useMatomo()
    useEffect(() => {
        props.authenticationHandler.getTokenFromStorageAndValidate()
        trackPageView({href: location.pathname})
    }, [location]);

    useEffect(() => {
        if (!token) {
            props.authenticationHandler.redirect();
        } else {
            const decoded = jwt_decode(props.authenticationHandler.getToken());
            if(Object.hasOwn(decoded, "userid")){
                setCdsid(decoded.userid)
            } else if (decoded.appid && decoded.appid==="6c43ca6e-5b52-5783-aa12-8b5aa0921968"){
                setCdsid("tmcops")
            }
            props.authenticationHandler.removeTokenFromURL();
        }

    }, [token])

    useEffect(() => {
        createFormData.cdsid = cdsid;
        createFormData.contactInfo = [cdsid + "@ford.com"];
        if(cdsid !== 'notFound') {
            pushInstruction('setUserId', cdsid)
        }
    }, [cdsid])

    return !props.authenticationHandler.getToken() ?
        (
            <></>
        ) :
        (
            <>
            <Switch>
                <Route path="/updateAdvisory/:updateId">
                    <MuiThemeProvider theme={formLabelsTheme}>

                        <UpdateAdvisory
                            cdsid={cdsid}
                            bulkMailInfoAPIClient={
                                new BulkMailInfoAPIClient(config.baseApiUrl)
                            }
                            executiveBulkMailAPIClient={
                                new ExecutiveBulkMailInfoAPIClient(config.baseApiUrl)
                            }
                            updateAdvisoryClient={
                                new UpdateAdvisoryAPIClient(config.baseApiUrl)
                            }
                        />
                    </MuiThemeProvider>

                </Route>
                <Route path="/createAdvisory">
                    <MuiThemeProvider theme={formLabelsTheme}>
                        <CreateAdvisory
                            cdsid={cdsid}
                            formData={createFormData}
                            bulkMailInfoAPIClient={
                                new BulkMailInfoAPIClient(config.baseApiUrl)
                            }
                            submitFormClient={
                                new CreateAdvisoryAPIClient(config.baseApiUrl)
                            }
                        />
                    </MuiThemeProvider>
                </Route>
                <Route path="/successConfirmation">
                    <AdvisorySuccessConfirmation
                        cdsid={cdsid}
                    />
                </Route>
                <Route path="/failureConfirmation">
                    <AdvisoryFailureConfirmation
                        cdsid={cdsid}
                    />
                </Route>
                <Route path="/feedbackSuccessConfirmation">
                    <FeedbackSuccessConfirmation
                        cdsid={cdsid}
                    />
                </Route>
                <Route path="/feedbackFailureConfirmation">
                    <FeedbackFailureConfirmation
                        cdsid={cdsid}
                    />
                </Route>
                <Route path="/feedbackForm">
                    <SiteFeedbackForm
                        cdsid={cdsid}
                        submitFormClient={
                            new FeedbackFormClient(config.baseApiUrl)
                        }
                    />
                </Route>
                <Route path="/emailFeedbackForm">
                    <EmailFeedbackForm cdsid={cdsid}
                                       submitFormClient={
                                           new FeedbackFormClient(config.baseApiUrl)
                                       }/>
                </Route>
                <Route path="/">
                    <div>
                        <ViewAllAdvisories
                            cdsid={cdsid}
                            viewAllAdvisoriesAPIClient={
                                new ViewAllAdvisoriesAPIClient(config.baseApiUrl)
                            }
                            getHealthApiClient={new GetHealthAPIClient(config.baseApiUrlWithoutApiPath)}
                        />
                    </div>
                </Route>
            </Switch>
            <Footer />
    </>
        );
}
