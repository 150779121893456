import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import '../../../AdvisoryForm.css'

const PreCheckModal = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const openModal = () => {
        setIsModalVisible(true);
    }
    const closeModal = () => {
        setIsModalVisible(false);
    }

    function getRegionsImpacted() {

        let regions = props.formData.regions
        let regionsToReturn = []
        let regionKeys = Object.keys(regions)
        for (let index in regionKeys) {
            if (regions[regionKeys[index]] === true) {
                regionsToReturn.push(regionKeys[index]);
            }
        }
        if (regionsToReturn.length === 0) return "None"
        return regionsToReturn.map(name => mapRegionToReadableName(name)).join(", ")
    }

    function mapRegionToReadableName(regionName) {
        if (regionName === "europeanUnion") return "EU"
        if (regionName === "northAmerica") return "NA"
        if (regionName === "southAmerica") return "SA"
        if (regionName === "internationalMarketGroup") return "IMG"
        if (regionName === "greaterChina") return "GCA"
        else return "I'm a region that hasn't been added yet: if you receive this message please contact the FAM team"
    }

    function getAdvisoryType() {
        if (props.formData.status === "Informational") return "Informational"
        if (props.formData.status === "Initial" || props.formData.status === "Update" || props.formData.status === "Final") return "Incidental"
        else return "I'm an advisory type that hasn't been added yet: if you receive this message please contact the FAM team"
    }

    function getRecipients() {
        if (props.bulkMails.length === 0 && props.formData.additionalEmails.length === 0) return "None"
        return [...props.bulkMails, ...props.formData.additionalEmails].join(", ")
    }

    return (
        <div>
            <Button
                onClick={() => {
                    openModal()
                }}
                disabled={props.buttonDisabled}
                aria-label={"confirmButton"}
                    color="primary"
                    variant="contained"
            >
                Confirm
            </Button>

            <Dialog
                open={isModalVisible}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth={"sm"}
            >
                <DialogTitle className={"modalHeader"}>Ready to Send the Advisory?</DialogTitle>
                <DialogContent className={"modalContent"}>
                    <DialogContentText>
                        <strong>Advisory Type:</strong> {getAdvisoryType()}
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Advisory Status:</strong> {props.formData.status}
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Priority:</strong> {props.formData.priority}
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Regions Impacted:</strong> {getRegionsImpacted()}
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Product/Application:</strong> {props.formData.product}
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Recipients:</strong> {getRecipients()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={"modalContent"}>
                    <Button onClick={closeModal} color="default" variant="contained">
                        Edit
                    </Button>
                    <Button
                        onClick={() => {
                            document.getElementById("SendEmailLoader").hidden = false;
                            props.handleSubmit();
                        }}
                        color="primary" variant="contained" autoFocus>
                        Send Email
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default PreCheckModal;