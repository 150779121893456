import Grid from "@material-ui/core/Grid";
import React from "react";
import {observer} from "mobx-react";
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {useHistory} from "react-router-dom";
import FAMLogo from "../assets/Logos/FAM_advisory_trim.svg";
import FAMFeedbackIcon from "../assets/Logos/FAM_feedbackIcon.svg";

export const Header = observer(({...props}) => {
    const {trackEvent} = useMatomo();
    let history = useHistory();
    let homePageTitle = "Ford Advisory Management"

    function goToPath(path) {
        history.push({
            pathname: path
        });
        trackEvent({
            category: path,
            action: 'Clicked ' + path + ' link in header',
            documentTitle: 'Ford Advisory Management',
            href: 'advisory.sre.ford.com',
        })
    }

    if (props.feedbackLink === false) {
        return (
            <Grid container spacing={3} className="pageContainer">
                <>
                    <Grid style={{justifyContent: "flex-start", alignItems: "center", textAlign: "left"}} item xs={7}
                          className="pageHeader">
                        <button className="feedbackLink" style={{justifyContent: "flex-start", width: "100%"}}
                                onClick={() => goToPath("/")}>

                            <img style={{width: "7%", marginRight: "10px"}} src={FAMLogo} alt={"Fam Logo"}/>
                            <div style={{alignSelf: "center", justifySelf: "flex-start"}}>
                                {homePageTitle}
                            </div>

                        </button>
                    </Grid>
                    <Grid style={{justifyContent: "flex-end"}} item xs={5} className="pageHeaderCdsid">
                        <div style={{justifySelf: "flex-end"}} className="pageHeaderCdsid">
                            <p>{props.cdsid}</p>
                        </div>
                    </Grid>
                    <Grid className="pageLabel" item xs={12}>
                            <p>{props.title}</p>
                    </Grid>
                </>
            </Grid>
        )
    } else {
        return (
            <>
                <Grid style={{justifyContent: "flex-start", alignItems: "center", textAlign: "left"}} item xs={7}
                      className="pageHeader">
                    <button className="feedbackLink" style={{justifyContent: "flex-start", width: "100%"}}
                            onClick={() => goToPath("/")}>

                        <img style={{width: "7%", marginRight: "10px"}} src={FAMLogo} alt={"Fam Logo"}/>
                        <div style={{alignSelf: "center", justifySelf: "flex-start"}}>
                            {homePageTitle}
                        </div>

                    </button>
                </Grid>
                <Grid style={{justifySelf: "flex-end"}} item xs={5}
                      className="pageHeaderFeedback">
                    <button className="feedbackLink" onClick={() => goToPath("/feedbackForm")}
                            style={{alignSelf: "center", alignContent: "center"}}>
                        <div style={{alignSelf: "center", justifySelf: "flex-start", marginRight: "10px"}}>
                            Share Your Feedback
                        </div>
                        <img src={FAMFeedbackIcon} alt={"Feedback Icon"}/>
                    </button>
                    <div style={{justifySelf: "flex-end"}} className="pageHeaderCdsid">
                        <p>{props.cdsid}</p>
                    </div>
                </Grid>

                <Grid className="pageLabel" item xs={12}>
                    <p>{props.title} {props.updateId}</p>
                </Grid>
            </>
        )
    }
})