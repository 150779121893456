import React from "react";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import AccessTime from "@material-ui/icons/AccessTime";

export const DateTimePickers = props => {
  const usersTimeZone = new Date()
    .toLocaleString("en-US", {
      timeZoneName: "short"
    })
    .split(" ")
    .pop();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="datePickerContainer">
            <KeyboardDatePicker
                className="datePicker"
                format="MM/dd/yyyy"
                margin="normal"
                variant="inline"
                required={props.required}
                id={props.idRoot + "DatePicker"}
                label={props.datePickerLabel}
                value={props.date}
                onChange={date => props.setDate(date)}
                InputLabelProps={{
                    shrink: true
                }}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
                helperText={props.helperText}
            />
            <KeyboardTimePicker
                className="timePicker"
                margin="normal"
                variant="inline"
                required = {props.required}
                id={props.idRoot + "TimePicker"}
                label={props.timePickerLabel}
                value={props.date}
                onChange={date => props.setDate(date)}
                KeyboardButtonProps={{
                    "aria-label": "change time"
                }}
                keyboardIcon={<AccessTime/>}
                InputLabelProps={{
                    shrink: true
                }}
                helperText={props.helperText}
            />
            <div className="lightGray">{"(" + usersTimeZone + ")"}</div>
        </div>
      </MuiPickersUtilsProvider>
      <div className="lightGray">
        {props.date
          ? "GMT: " +
            props.date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "GMT"
            })
          : null}
      </div>
    </div>
  );
};
