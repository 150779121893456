import React from "react";

export function DisplayInvalidEmailMessage({invalidEmails}) {

    return (
        <>
            {invalidEmails !== "" && invalidEmails !== undefined && (
                <p className="emailErrorMessage">
                    Email format invalid - emails will not be sent to: {invalidEmails}
                </p>
            )}
        </>

    );
}

export function MandatoryFieldsMessage(shouldDisplayMessage) {
    if (shouldDisplayMessage) return <p className="emailErrorMessage">"There is a problem with one of the advisory fields, please resolve any errors shown above to be able to submit your Advisory!"</p>
    else return <p/>
}
