import React from "react";
import './Confirmation.css'
import Grid from "@material-ui/core/Grid";
import {Header} from "../Header";
import {FAMCommunityLinks} from "./CommunityChannelInfo";

export const FeedbackFailureConfirmation = (props) => {

    return (
    <>
        <Grid>
            <Header cdsid={props.cdsid} title={"Feedback Confirmation"} feedbackLink={false}/>
            <br/>
            <div className={"mainContent"}>
                <h2><span className={"exclamation"}>&#9888;</span> Oops!</h2>
                <p>Sorry, we encountered a problem submitting your feedback.</p>
                <p>The FAM team has been notified and will look into the issue you've encountered.</p>
                <p>In the meantime, please try to resubmit your feedback or reach out to us on our community channels:</p>
                <FAMCommunityLinks/>
                <br/>
            </div>
        </Grid>

    </>
    );
};
