import Grid from "@material-ui/core/Grid";
import React from "react";
import {observer} from "mobx-react";

export const DisplayBulkmails = observer(({...props}) => {
    return (<>
            <h4>NOTICE: If you are trying to create a test advisory, please use our “Pre-Prod” environment found in the footer. (Must be on Ford intranet)</h4>
            <Grid item xs={12}>
                <label className="bulkmailLabel">Bulkmails</label>
                <p className="bulkmailBodyText">
                    {props.bulkMail.join(", ")}
                </p>
            </Grid>
    </>)
});