import React from "react";
import Grid from "@material-ui/core/Grid";
import {ProductSelector} from "../../ProductSelector";
import {RegionSelector} from "../../RegionSelector";
import "date-fns";
import {observer} from "mobx-react";
import {DisplayBulkmailWarning} from "../../DisplayBulkmailWarning";
import {Header} from "../../Header";
import {getAdditionalEmailsChipInputField, getContactInfoChipInputField} from "../../FormComponents";
import {MandatoryFieldsMessage} from "../../DisplayInvalidEmailMessage";
import PreCheckModal from "./PreCheckModal";
import {
    incidentOrAdvisoryLabel,
    informationalCheckbox,
    removeOrAddRootCause,
    rootCauseTextField,
    showBulkmails,
    showUsersImpactedField
} from "../Common";
import {
    businessImpactEndTimePicker,
    incidentEndDateTimePicker,
    incidentStartDateTimePicker,
    removeOrAddBusinessImpactEndTime,
    removeOrAddEndTime,
    showBusinessImpactStartTime
} from "../DateTimePickers";
import {
    displayStatusAndPriorityForEverythingButInformational,
    impactDetailsTextField,
    productNameTextField,
    situationBackgroundTextField
} from "../CreateSpecificComponents";

export const CreateAdvisoryForm = observer(
    ({data, handleStatusChange, handleRegionChange, submitDisabled, ...props}) => {

        return (
            <><Header title={"Create " + data.status + " Advisory"} cdsid={data.cdsid}/>
                <Grid container spacing={3} className="pageContainer">
                    {informationalCheckbox(props, props.isInformationalAdvisory, false)}
                    {displayStatusAndPriorityForEverythingButInformational(data, props, handleStatusChange)}

                    <RegionSelector data={data} handleRegionChange={handleRegionChange}/>
                    <Grid item xs={12}>
                        <h2 className="sectionHeaders"> {incidentOrAdvisoryLabel(data.status)} Info </h2>
                    </Grid>
                    {productNameTextField(data)}
                    <Grid item xs={12}>
                        <ProductSelector data={data}/>
                    </Grid>
                    {incidentStartDateTimePicker(data)}
                    {data.status !== "Initial" ?
                        (<Grid item xs={12}>
                            {incidentEndDateTimePicker(data)}
                        </Grid>) :
                        removeOrAddEndTime(data, props)}
                    <Grid item xs={12}>
                        {getContactInfoChipInputField(data, props, true)}
                    </Grid>
                    <Grid item xs={12}>
                        {situationBackgroundTextField(data)}
                    </Grid>
                    {data.status === "Initial" ?
                        removeOrAddRootCause(props, data) :
                        <Grid item xs={12}>
                            {rootCauseTextField(data)}
                        </Grid>}
                    <Grid item xs={12}>
                        <h2 className="sectionHeaders">Business Impact Details</h2>
                        {showBusinessImpactStartTime(data)}
                    </Grid>
                    {data.status !== "Initial" ? (
                        <Grid item xs={12}>
                            {businessImpactEndTimePicker(data)}
                        </Grid>
                    ) : (
                        removeOrAddBusinessImpactEndTime(data, props)
                    )}
                    <Grid item xs={12}>
                        {showUsersImpactedField(data)}
                    </Grid>
                    <Grid item xs={12}>
                        {impactDetailsTextField(data)}
                    </Grid>
                    {showBulkmails(data, props)}
                    <Grid item xs={12}>
                        {getAdditionalEmailsChipInputField(data, props)}
                        <DisplayBulkmailWarning sendBulkmails={data.sendBulkmails}/>
                    </Grid>
                    <Grid item xs={12} className="GenerateEmailButton">
                        {MandatoryFieldsMessage(submitDisabled(data, props.showEndDate, props.showBiEndDate))}
                        <br/>
                        <br/>
                        <PreCheckModal formData={data} bulkMails={props.bulkMail} handleSubmit={props.handleSubmit}
                                       buttonDisabled={props.invalidEmails !== "" || submitDisabled(data, props.showEndDate, props.showBiEndDate)}/>
                        <div className="loader" id="SendEmailLoader" hidden={true}/>
                    </Grid>
                </Grid>
            </>
        );
    }
);
