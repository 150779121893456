import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import React, {useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {ViewAllAdvisoriesTableColumn} from "./ViewAllAdvisoriesTableColumn";

export const ViewAllAdvisoriesTable = props => {

    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortBy, setSortBy] = useState("Date Issued");
    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: "#F2F2F2"
        }
    }))(TableCell);
    const StyledTableRow = withStyles(() => ({
        hover: {
            cursor: "pointer"
        }
    }))(TableRow);

    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow data-testid="tableHead">
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    columnName={"ID"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <StyledTableCell>
                            <ViewAllAdvisoriesTableColumn
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                                columnName={"Date Issued"}
                                allFilteredAdvisories={props.allFilteredAdvisories}
                                setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <ViewAllAdvisoriesTableColumn
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                sortOrder={sortOrder}
                                setSortOrder={setSortOrder}
                                columnName={"Application"}
                                allFilteredAdvisories={props.allFilteredAdvisories}
                                setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                            />
                        </StyledTableCell>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    columnName={"Status"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    columnName={"Last Modified"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    columnName={"Modified By"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? props.allFilteredAdvisories.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
                        : props.allFilteredAdvisories)
                        .map((advisory, index) => (
                            <StyledTableRow
                                onClick={() => {
                                    history.push("/updateAdvisory/" + advisory.id);
                                }}
                                hover
                                data-testid={"tableRow" + index}
                                key={"advisory-table" + index}
                            >
                                <Hidden xsDown>
                                    <TableCell>{advisory.id}</TableCell>
                                </Hidden>
                                <TableCell>
                                    {advisory.advisoryForm.situationBackground.submittedTime.toLocaleString(
                                        "en-US",
                                        {
                                            month: "numeric",
                                            day: "numeric",
                                            year: "numeric"
                                        }
                                    )}
                                </TableCell>
                                <TableCell>{advisory.advisoryForm.product}</TableCell>
                                <Hidden xsDown>
                                    <TableCell>{advisory.advisoryForm.status}</TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                    <TableCell>
                                        {advisory.advisoryForm.submittedTime.toLocaleString(
                                            "en-US",
                                            {
                                                month: "numeric",
                                                day: "numeric",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric"
                                            }
                                        )}
                                    </TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                    <TableCell>{advisory.advisoryForm.cdsid}</TableCell>
                                </Hidden>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                className="tablePagination"
                rowsPerPageOptions={[20, {label: 'All', value: props.allFilteredAdvisories.length}]}
                component="div"
                count={props.allFilteredAdvisories.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="View:"
            />
        </Paper>
    );
};
