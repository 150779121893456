import axios from "axios";
import {getRequestConfig} from "../../../ClientRequestHelper";

export default class CreateAdvisoryAPIClient {
  constructor(serviceUrl) {
    this.serviceUrl = serviceUrl;
  }
  submit(formData) {
    const url = `${this.serviceUrl}/advisory`;
    return axios
        .post(url, formData, getRequestConfig())
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
