import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";

export const RegionSelector = observer(({handleRegionChange, data}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} className="regionSelector">
            <FormLabel component="legend">
                REGION(S) IMPACTED
                <IconButton
                    className="infoButton"
                    color="primary"
                    disableRipple
                    onClick={handleClickOpen}
                >
                    <InfoOutlinedIcon fontSize="small"/>
                </IconButton>
                <Dialog
                    aria-labelledby="regionsDialog"
                    onClose={handleClose}
                    open={open}
                >
                    <DialogTitle id="regionsDialog">Regions</DialogTitle>
                    <List className="regionsList">
                        <ListItem>
                            <ListItemText>NA: North America</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>EU: European Union</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>SA: South America</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>GCA: Greater China</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>IMG: International Market Group</ListItemText>
                        </ListItem>
                    </List>
                </Dialog>
            </FormLabel>
            <FormControl>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="northAmerica"
                                value="NorthAmerica"
                                color="primary"
                                checked={data.regions.northAmerica || false}
                                onChange={handleRegionChange("northAmerica")}
                            />
                        }
                        label="NA"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="europoeanUnion"
                                value="EuropeanUnion"
                                color="primary"
                                checked={data.regions.europeanUnion || false}
                                onChange={handleRegionChange("europeanUnion")}
                            />
                        }
                        label="EU"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="southAmerica"
                                value="SouthAmerica"
                                color="primary"
                                checked={data.regions.southAmerica || false}
                                onChange={handleRegionChange("southAmerica")}
                            />
                        }
                        label="SA"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="greaterChina"
                                value="GreaterChina"
                                color="primary"
                                checked={data.regions.greaterChina || false}
                                onChange={handleRegionChange("greaterChina")}
                            />
                        }
                        label="GCA"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="internationalMarketGroup"
                                value="InternationalMarketGroup"
                                color="primary"
                                checked={data.regions.internationalMarketGroup || false}
                                onChange={handleRegionChange("internationalMarketGroup")}
                            />
                        }
                        label="IMG"
                    />
                </FormGroup>
            </FormControl>
        </Grid>
    );
});
