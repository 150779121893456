import Grid from "@material-ui/core/Grid";
import React from "react";
import Link from "@material-ui/core/Link";
import {DateTimePickers} from "../DateTimePickers";
import {DurationDisplayHelper} from "../DurationDisplayHelper";
import {incidentOrAdvisoryLabel, removeOrAddPattern, showBusinessEndDate} from "./Common";

export function showBusinessImpactStartTime(data) {
    return <>
        <div>
            <h3> Business Impact Start</h3>
        </div>
        <DateTimePickers
            idRoot="biStart"
            datePickerLabel="Start Date"
            timePickerLabel="Start Time"
            date={data.businessImpactDetails.biStartDate}
            setDate={newDate =>
                (data.businessImpactDetails.biStartDate = newDate)
            }
        />
    </>;
}

export function addBusinessImpactEndTime(props) {
    return <Link
        id="addBiEndTime"
        onClick={() => props.setShowBiEndDate(true)}
    >
        + ADD END TIME
    </Link>;
}

export function removeBusinessImpactEndTime(props) {
    return <Link
        id="removeBiEndTime"
        onClick={() => props.handleRemoveBiEnd()}
    >
        - REMOVE END TIME
    </Link>;
}

export function removeEndDateButton(props) {
    return <Link
        onClick={() => props.handleRemoveEnd()}
    >
        - REMOVE END TIME
    </Link>;
}

export function addEndTimeButton(props) {
    return <Link
        onClick={() => props.setShowEndDate(true)}
    >
        + ADD END TIME
    </Link>;
}

export function removeOrAddBusinessImpactEndTime(data, props) {
    let removeButton = removeBusinessImpactEndTime(props);
    let fieldToShow = businessImpactEndTimePicker(data);
    let addButton = addBusinessImpactEndTime(props);
    let removeOrAddBusinessImpactEndTimeView = removeOrAddPattern(showBusinessEndDate(props, data), removeButton, fieldToShow, addButton);

    return removeOrAddBusinessImpactEndTimeView
}

export function showEndTime(data, props){
    return (props.showEndDate || data.endDate)
}

export function removeOrAddEndTime(data, props) {
    let removeButton = removeEndDateButton(props);
    let fieldToShow = incidentEndDateTimePicker(data);
    let addButton = addEndTimeButton(props);
    let removeOrAddEndTimeView = removeOrAddPattern(showEndTime(data,props), removeButton, fieldToShow, addButton);

    return removeOrAddEndTimeView
}

export function businessImpactEndTimePicker(data) {
    return <>
        <h3> Business Impact End</h3>
        <DateTimePickers
            idRoot="bIEnd"
            datePickerLabel="End Date"
            timePickerLabel="End Time"
            date={data.businessImpactDetails.biEndDate}
            setDate={newDate =>
                (data.businessImpactDetails.biEndDate = newDate)
            }
            required={true}
            helperText="Required"
        />
        <DurationDisplayHelper
            startDate={data.businessImpactDetails.biStartDate}
            endDate={data.businessImpactDetails.biEndDate}
            mandatoryField={true}
        />
    </>;
}

export function incidentEndDateTimePicker(data) {
    return <>
        <h3>{incidentOrAdvisoryLabel(data.status)} End</h3>
        <DateTimePickers
            idRoot="end"
            datePickerLabel="End Date"
            timePickerLabel="End Time"
            date={data.endDate}
            setDate={newDate => {
                data.endDate = newDate;
            }}
            required={true}
            helperText="Required"
        />
        <DurationDisplayHelper
            startDate={data.startDate}
            endDate={data.endDate}
            mandatoryField={true}
        />
    </>;
}

export function incidentStartDateTimePicker(data) {
    return <Grid item xs={12}>
        <h3>{incidentOrAdvisoryLabel(data.status)} Start</h3>
        <DateTimePickers
            idRoot="start"
            datePickerLabel="Start Date"
            timePickerLabel="Start Time"
            date={data.startDate}
            setDate={newDate => {
                data.startDate = newDate;
            }}
        />
    </Grid>;
}