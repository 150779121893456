import React, {useState} from "react";
import './FeedbackForm.css'
import ReactStars from "react-rating-stars-component";
import Button from "@material-ui/core/Button";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {Header} from "../Header";

export const SiteFeedbackForm = observer(
    ({submitFormClient, ...props }) => {
    const [name, setName] = useState("");

    const [feedbackText, setfeedbackText] =useState("");
    const [overallStarsRating, setoverallStars] =useState("");
    const [recommendStarsRating, setrecommendStars] =useState("");
    const commonStuffInStars = onChangeFunction => {
        return {
            size: 100,
            count: 5,
            isHalf: true,
            value: 0,
            color: "#383838",
            activeColor: "#ffd700",
            onChange: newValue => {
                onChangeFunction(newValue)
            }
        }
    }

    const handleNameChange = event =>{
        setName(event.target.value)
    }

    const handleFeedbackText = event =>{
        setfeedbackText(event.target.value)
    }

    const {trackEvent} = useMatomo();
    let history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault()
        const feedback = {
            cdsid:props.cdsid,
            nameAndRole: name,
            feedbackMessage: feedbackText,
            overallRating: overallStarsRating.toString(),
            recommendRating: recommendStarsRating.toString(),
            type: "Site"
        }
        submitFormClient
            .submit(feedback)
            .then(() => {
                history.push({
                    pathname: "/feedbackSuccessConfirmation"
                });
                trackEvent({
                    category: 'Feedback Submit Form',
                    action: 'Feedback Successful',
                    documentTitle: 'Ford Advisory Management',
                    href: 'advisory.sre.ford.com',
                })
            })
            .catch(() => {
                history.push({
                    pathname: "/feedbackFailureConfirmation"
                });
                trackEvent({
                    category: 'Feedback Submit Form',
                    action: 'Feedback failed',
                    documentTitle: 'Ford Advisory Management',
                    href: 'advisory.sre.ford.com',
                })
            });
    }

    return (
        <>
            <Header cdsid={props.cdsid} title={"Feedback"} feedbackLink={false}/>
            <form onSubmit={handleSubmit}>
                <div className={"feedback-Form"}>
                    <div className={"feedback-text"}>
                        <label>
                            1. What is your name & role at Ford?
                        </label>
                        <div className={"element"}>
                            <textarea type="text" id="nameTextArea" onChange={(e) => handleNameChange(e)}/>
                        </div>
                    </div>
                    <div className={"feedback-text"}>
                        <label>
                            2. How would you rate your experience using Ford Advisory Management?
                        </label>
                        <div className={"starTextGreatness"}>
                            <ReactStars {...commonStuffInStars(setoverallStars)} />
                        </div>
                    </div>
                    <div className={"feedback-text"}>
                        <label>
                            3. How likely are you to recommend this tool?
                        </label>
                        <div className={"starTextLikeliness"}>
                            <ReactStars {...commonStuffInStars(setrecommendStars)} />
                        </div>
                    </div>
                    <div className={"feedback-text"}>
                        <label>
                            4. Please share your thoughts and feedback below:
                        </label>
                        <div className={"feedbackInput"}>
                            <textarea type="text" id={"feedbackTextArea"} onChange={(e) => handleFeedbackText(e)}/>
                        </div>
                    </div>
                    <div className={"submitButtonDiv"}>
                        <Button color="primary" variant="contained" className={"submitButton"} id="submitFeedbackButton"
                                type={"submit"}>SUBMIT</Button>
                    </div>
                </div>
            </form>
        </>
    );
})
