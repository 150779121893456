import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import {observer} from "mobx-react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";
import {CSVLink} from "react-csv";
import {getNewDate} from "../../DateCreator";
import GetAppIcon from "@material-ui/icons/GetApp";
import {jsonToCsvDataFormatter} from "../JsonToCsvDataFormatter";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import {advisorySearch} from "../AdvisorySearch";
import {ViewAllAdvisoriesTable} from "./ViewAllAdvisoriesTable";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import useStopwatch from "../../hooks/useStopWatch";
import {Header} from "../Header";
import Button from "@material-ui/core/Button";


export const ViewAllAdvisories = observer(({...props}) => {
    const [allAdvisories, setAllAdvisories] = useState([]);
    const [firstPageOfAdvisories, setFirstPageOfAdvisories] = useState([]);
    const [allFilteredAdvisories, setAllFilteredAdvisories] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [page, setPage] = useState(0);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isHealthy, setHealthy] = useState(true);
    const history = useHistory();
    const {timeInMillisRef, pause} = useStopwatch();
    const {trackEvent} = useMatomo();

    const setFilteredAdvisories = query => {
        const advisoriesToUse = allAdvisories.length > 0 ? allAdvisories : firstPageOfAdvisories
        setPage(0)
        if (query === "") {
            setAllFilteredAdvisories(advisoriesToUse);
        } else {
            setAllFilteredAdvisories(advisorySearch(query, advisoriesToUse));
        }
    };

    useEffect(() => {
        setFilteredAdvisories("")
    }, [firstPageOfAdvisories, allAdvisories])

    useEffect(() => {

        props.viewAllAdvisoriesAPIClient
            .getPageOfAdvisories()
            .then(response => {
                const timeInSeconds = timeInMillisRef.current / 1000
                trackEvent({category: "load time", action: "advisory page loaded", value: timeInSeconds})
                setFirstPageOfAdvisories(response);
                setCsvData(jsonToCsvDataFormatter(response));
                setDataLoaded(true);
            })
            .catch(error => console.warn(error));

        props.viewAllAdvisoriesAPIClient
            .getAllAdvisories()
            .then(response => {
                pause()
                const timeInSeconds = timeInMillisRef.current / 1000
                trackEvent({category: "load time", action: "all advisories loaded", value: timeInSeconds})
                setAllAdvisories(response);
                setCsvData(jsonToCsvDataFormatter(response));
                setDataLoaded(true);
            })
            .catch(error => console.warn(error));
        props.getHealthApiClient
            .getHealth()
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                setHealthy(false)
                console.warn(error)
            });


    }, []);

    return (
        <Grid container spacing={3} className="pageContainer">
            {isHealthy ? <></> :
                <Grid item xs={12} className="banner">
                    <p>Ford Advisory Management is currently experiencing intermittent issues contacting the Ford email
                        server (mrl.azell.com).</p>
                    <p>The email server may be down or experiencing network issues.</p>
                    <p>You may experience failures sending advisories during this time.</p>
                </Grid>
            }
            {isDataLoaded ? (
                <>
                    <Header cdsid={props.cdsid} title={"Advisory List"}/>
                    <Grid container item md>
                        <Grid item md className={"createAdvisoryGrid"}
                              style={{
                                  display: "flex",
                                  height: "100%"
                              }}>
                            <Button
                                className="createAdvisoryButton"
                                color="primary"
                                variant="contained"
                                disableRipple
                                onClick={() => history.push("/createAdvisory")}
                                style={{display: "inline-block"}}
                            >

                                <AddCircleOutlineIcon
                                    className="createAdvisoryIcon"
                                    fontSize="large"
                                    style={{verticalAlign: "middle"}}
                                />
                                <span style={{alignItems:"center"}}>
                                    Create an Advisory
                                </span>
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Press Enter to search by id, date, app, status, or modified by"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon color="disabled"/>
                                        </InputAdornment>
                                    )
                                }}
                                onBlur={(event) => {
                                    setFilteredAdvisories(event.target.value)
                                }}
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        setFilteredAdvisories(event.target["value"])
                                    }

                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ViewAllAdvisoriesTable
                            page={page}
                            setPage={setPage}
                            setAllFilteredAdvisories={setAllFilteredAdvisories}
                            allFilteredAdvisories={allFilteredAdvisories}
                        />
                    </Grid>
                    <Grid item md>
                        <CSVLink
                            className="csvLink"
                            data={csvData}
                            filename={"Advisories_Data_" + getNewDate() + ".csv"}
                            onClick={() => trackEvent({category: "download", action: "all advisories csv", value: 1})}
                        >
                            <IconButton
                                className="exportAdvisoryCSVButton"
                                color="primary"
                                disableRipple
                            >
                                <GetAppIcon className="exportAdvisoryCSVIcon" fontSize="small"/>
                                Export Advisories (.csv)
                            </IconButton>
                        </CSVLink>
                    </Grid>
                </>
            ) : (
                <>
                    <Header cdsid={props.cdsid}/>
                    <div className="loadingAdvisories">
                        <div className="loadingAdvisoriesText">Fetching advisories ...</div>
                        <img
                            src={require("../../Graphics/FAM-Loading-Screen-Preloader.gif")}
                            alt="Loading"
                        />
                    </div>
                </>
            )}
        </Grid>
    );
});
