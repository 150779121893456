import React from "react";

export function DurationDisplayHelper(props) {


    function displayDuration(startDate, endDate, mandatoryField) {
        let duration = Math.abs(startDate - endDate);

        let durationObj = {days: 0, hours: 0, minutes: 0};
        durationObj.days = Math.floor(duration / 86400000);
        durationObj.hours = Math.floor((duration % 86400000) / 3600000);
        durationObj.minutes = Math.floor(((duration % 86400000) % 3600000) / 60000);

        if (mandatoryField && endDate === null) {
            return (
                <div className={"warningMessage"}>
                    {"Missing end time and date, please enter your best estimation"}
                </div>
            )
        }

        const sign = endDate - startDate >= 0 ? "" : "-";
        return (
            <div className={"lightGray"}>{
                "Duration: " +
                sign +
                " " +
                durationObj.days +
                " days, " +
                durationObj.hours +
                " hours, " +
                durationObj.minutes +
                " minutes"}
            </div>
        );
    }

    return (
        <>
            {displayDuration(props.startDate, props.endDate, props.mandatoryField)}
        </>
    );
}
