export default function constructConfig() {
  var hostName = window.location.hostname;
      
  const prodAdfsUrl = "https://corp.sts.ford.com/adfs/oauth2/authorize?client_id=urn:fam_gcp_adfs:clientid:web_famapplication:prod&resource=urn:fam_gcp_adfs:resource:web_famapplication:prod&response_type=token&redirect_uri=";
  const qaProdAdfsUrl =  "https://corpqa.sts.ford.com/adfs/oauth2/authorize?client_id=urn:fam_gcp_adfs:clientid:web_famapplication:qa&resource=urn:fam_gcp_adfs:resource:web_famapplication:qa&response_type=token&redirect_uri=";
  const devProdAdfsUrl =  "https://corpqa.sts.ford.com/adfs/oauth2/authorize?client_id=urn:fam_gcp_adfs:clientid:web_famapplication:dev&resource=urn:fam_gcp_adfs:resource:web_famapplication:dev&response_type=token&redirect_uri=";

  const validationconfig = {
    inputFields: {
      product: {
        label: "Product",
        maxLength: 254,
      },
      situationbackground: {
        label: "Situation Background",
        maxLength: 2000,
      },
      rootCause: {
        label: "Root Cause",
        maxLength: 2000,
      },
      usersImpacted: {
        label: "Users Impacted",
        maxLength: 2000,
      },
      impactDetails: {
        label: "Impact Details",
        maxLength: 2000,
      },
      updateSituationBackground: {
        label: "Update Situation/Background",
        maxLength: 2000,
      },
      updateBusinessImpact: {
        label: "Update Business Impact Details",
        maxLength: 2000,
      }
    },
  };


  if (hostName.includes("advisory.sre") ||
      hostName.includes("advisory-prod") ||
      hostName.includes("advisory.prod")) {
    return {
      baseUrl: "https://advisory.prod.ford.com",
      baseApiUrl: "https://api.pd01e.gcp.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api.pd01e.gcp.ford.com/fam",
      adfsUrl: prodAdfsUrl,
      matomoShouldBeDisabled: true, //disabled now
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  } else if (hostName.includes("advisory.qa") ||
      hostName.includes("advisory-qa")) {
    return {
      baseUrl: "https://advisory.qa.ford.com",
      baseApiUrl: "https://api.qa01i.gcp.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api.qa01i.gcp.ford.com/fam",
      adfsUrl: qaProdAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    }
  } else if (hostName.includes("advisory.dev") ||
      hostName.includes("advisory-dev")) {
    return {
      baseUrl:
          "https://advisory.dev.ford.com",
      baseApiUrl: "https://api.d01i.gcp.ford.com/fam/api",
      baseApiUrlWithoutApiPath: "https://api.d01i.gcp.ford.com/fam",
      adfsUrl: devProdAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  } else if (hostName.includes("localhost")) {
    return {
      baseUrl: "http://localhost:3000",
      baseApiUrl: "http://localhost:8080/api",
      baseApiUrlWithoutApiPath: "http://localhost:8080",
      adfsUrl: devProdAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  }
}


